import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Container, Grid, Hidden, Typography } from '@material-ui/core'

import Illustration from '../../svg/marketing-page/marketing-audit-svg.svg'
import { useModal } from '../modal/modal-context';

const useStyles = makeStyles((theme) => ({

  wrapper: {
    background: [theme.palette.gradientDark.main],
    color: '#fff',
  },

  titleBox: {
    marginBottom: '2rem',
  },

  title: {
    paddingBottom: '15px',
    borderBottom: '1px solid',
  },

  priceBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: '2rem',

    '& > *': {
      padding: '.5rem',
      lineHeight: 1.2,
      display: 'block',
    },
  },

  price: {
    fontSize: '5rem',
    fontWeight: '800',
    color: [theme.palette.primary.main],
  },

  desc: {
    fontSize: '1rem',
    fontWeight: 500,
  },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      position: 'relative',
      overflow: 'hidden',
      padding: '100px 0 135px',
    },

    titleBox: {
      marginBottom: '8rem',
    },

    priceBox: {
      justifyContent: 'flex-start',
      marginBottom: '8rem',
    },

    price: {
      fontSize: '7rem',
    },
  
    desc: {
      fontSize: '1.8rem',
    },

    bg: {

      '& svg': {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 'calc(100vw / 12 * 6 - 30px)',
      },
    },

    circle: {
      position: 'absolute',
      width: '880px',
      height: '880px',
      borderRadius: '50%',
      background: [theme.palette.gradientBlue.main],
      bottom: 'calc(-880px + 500px)',
      left: 'calc(100vw / 12 * 8 - 30px)',
    },

    content: {
      position: 'relative',
      zIndex: 2,
    },
  },
}))

const MarketingAudit = () => {

  const classes = useStyles()

  const {show} = useModal()

  const modalTitle = 'Аудит рекламы'
  const modalDesc = 'Заполните форму заявки, и наш менеджер свяжется с вами в ближайшее время 🙂'
  const modalFields = {
    name: true,
    phone: true,
    mail: true,
  }

  return (
    <Box component="section" className={classes.wrap} id="marketing-audit">
      <Box className={classes.wrapper} py={{xs: 5, md: 10}}>
        <Hidden smDown>
          <Box className={classes.bg}>
            <Box className={classes.circle}></Box>
            <Illustration />
          </Box>
        </Hidden>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Grid container>
              <Grid item md={7} >
                <Box className={classes.titleBox}>
                  <Typography variant="h2" className={`${classes.title} title`}>не довольны своей <span className="gradient">рекламной </span><span className="gradient">кампанией?</span> закажите аудит у нас</Typography>
                  <Typography variant="body1" className={`${classes.subtitleRev} subtitle`}>Проведем экспресс-аудит вашей рекламы. Укажем на слабые места и возможности для улучшения конверсий</Typography>
                </Box>
                <Box className={classes.priceBox}>
                  <span className={classes.price}>1500</span>
                  <span className={classes.desc}>рублей<br/>за одну площадку</span>
                </Box>
                <Box className={classes.buttonBox} textAlign={{xs: 'center', md: 'left'}}>
                  <Button variant="contained" color="primary" size="large" onClick={() => show(modalTitle, modalDesc, modalTitle, modalFields)}>заказать аудит</Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default MarketingAudit
