import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    color: '#fff',
    padding: '5rem 0',
  },

  title: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: 1.4,
    textTransform: 'uppercase',

    '& span': {
      color: [theme.palette.primary.main],
    },
  },

  descBox: {
    paddingTop: '2rem',
    marginTop: '2rem',
    borderTop: '1px solid',
  },

  desc: {
    fontSize: '1.2rem',
  },

  [theme.breakpoints.up('md')]: {
    
    title: {
      fontSize: '3rem',
    },

    descBox: {
      margin: '2rem auto 0',
      width: 'calc(100% / 12 * 8)',
    },

    desc: {
      fontSize: '1.8rem',
    },
  },
}))

const MarketingPrices = () => {

  const { bgImg } = useStaticQuery(
    graphql`
      query {
        bgImg: file(relativePath: {eq: "marketing-bg.png"}) {
          childImageSharp {
            gatsbyImageData (
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const pluginImage = getImage(bgImg)

  const classes = useStyles()

  return (
    <BgImage image={pluginImage} Tag="section" className={classes.wrap}>
      <Box className={classes.wrapper}>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Box className={classes.titleBox} textAlign="center">
              <h2 className={`${classes.title}`}>стоимость настройки контекстной рекламы — <br/><span>от 10000 руб.</span><br/> стоимость ведения рекламы — <br/><span>от 8000 руб. в месяц</span></h2>
            </Box>
            <Box className={classes.descBox} textAlign="center">
              <p className={classes.desc}>
              Проведем анализ ваших конкурентов, выделим сильные стороны вашего бизнеса, соберем семантическое ядро и настроим рекламу в Яндекс и Google
              </p>
            </Box>
          </Box>
        </Container>
      </Box>
    </BgImage>
  )
}

export default MarketingPrices
