import React from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import StagesBox from '../page-parts/stages'
import Num1 from '../../svg/stages-numbers/num1.svg'
import Num2 from '../../svg/stages-numbers/num2.svg'
import Num3 from '../../svg/stages-numbers/num3.svg'
import Num4 from '../../svg/stages-numbers/num4.svg'
import Num5 from '../../svg/stages-numbers/num5.svg'
import Num6 from '../../svg/stages-numbers/num6.svg'

const useStyles = makeStyles((theme) => ({
  titleBox: {
    marginBottom: '5rem',
  },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      padding: '70px 0 150px',
    },

    titleBox: {
      marginBottom: '8rem',
    },
  },
}))

const MarketingStages = () => {

  const classes = useStyles()

  const stages = [
    {
      title: 'Анализ и стратегия',
      text: 'Определяем целевую аудиторию и ключевые цели, утверждаем бюджеты рекламной кампании',
      num: <Num1 />,
    },
    {
      title: 'Отладка аналитики',
      text: 'Настраиваем Яндекс.Метрику и Google Analytics. Устанавливаем счетчики, настраиваем цели, отслеживание звонков (колл-трекинг)',
      num: <Num2 />,
    },
    {
      title: 'Подготовка объявлений',
      text: 'Собираем самые продающие ключевые фразы, чистим от мусора (минус-слова). Согласовываем с заказчиком. Пишем релевантные текста под каждый запрос.',
      num: <Num3 />,
    },
    {
      title: 'Запуск рекламной кампании',
      text: 'Загружаем подготовленную кампанию в реламный кабинет. Добавляем дополнительные ссылки, уточнения, корректируем ставки. Проходим модерацию и запускаем рекламу.',
      num: <Num4 />,
    },
    {
      title: 'Анализируем эффективность',
      text: 'Во время всего периода сотрудничества выявляем и добавляем новые минус-фразы, корректируем ставки объявлений для повышения CTR объявлений.',
      num: <Num5 />,
    },
    {
      title: 'Отчеты',
      text: 'В конце каждой недели составляем отчеты о работе рекламных кампаний и отправляем их заказчику.',
      num: <Num6 />,
    },
  ]

  return (
    <Box className={classes.wrap} component="section" id="marketing-stages">
      <Box className={classes.wrapper} pt={5} pb={8}>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Box className={classes.titleBox}>
              <Typography variant="h2" className={`${classes.title} title`}>этапы работы</Typography>
            </Box>
            <Box className={classes.stagesBox}>
              <StagesBox content={stages} />
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default MarketingStages