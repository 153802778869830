import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'


const useStyles = makeStyles((theme) => ({

  wrapper: {
    padding: '1rem 0 5rem',
  },

  placementsIcons: {
    display: 'flex',
    alignItems: 'center',

    '& > *': {
      display: 'block',
      padding: '0 .5rem',
      flex: '0 0 50%',
    },
  },

  '@media (min-width: 768px)': {

  },

  [theme.breakpoints.up('md')]: {
    title: {
      // width: '80%',
    },

    placementsIcons: {
      justifyContent: 'space-around',
    }
  },

}))

const MarketingPlacements = () => {

  const classes = useStyles()

  return (
    <Box component="section" className={classes.wrap}>
      <Box className={`${classes.wrapper}`} py={3}>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Grid container alignItems="center">
              <Grid item md={5}>
                <Box className={classes.titleBox}>
                  <Typography variant="h2" className={`${classes.title} title`}><span className="gradient">рекламные площадки, </span>которые мы используем</Typography>
                </Box>
              </Grid>
              <Grid item md={7}>
                <Box className={classes.placementsIcons}>
                  <div>
                    <StaticImage
                      src="../../images/y-dir.png"
                      alt="Настройка Яндекс Директ"
                      quality={100}
                    />
                  </div>
                  <div>
                    <StaticImage
                      src="../../images/ads-g.png"
                      alt="Настройка Google Ads"
                      quality={100}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default MarketingPlacements
