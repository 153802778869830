import React from 'react'
import Layout from '../../../components/layout'
import MarketingMainBanner from '../../../components/marketing/main-banner';

import Illustration from '../../../svg/marketing-banner-svg.svg'
import MarketingInfoSection from '../../../components/marketing/info-section';
import MarketingAdvantages from '../../../components/marketing/advantages';
import MarketingPlacements from '../../../components/marketing/marketing-placements';
import MarketingAudit from '../../../components/marketing/audit';
import MarketingStages from '../../../components/marketing/marketing-stages';
import FormSection from '../../../components/page-parts/form-section';
import MarketingPrices from '../../../components/marketing/marketing-prices';
import Seo from '../../../components/seo';

const MarketingPage = () => {

  const bannerContent = {
    // ttlBlue: 'настройка ',
    // title: <span>контекстной рекламы</span>,
    subtitle: 'Привлечем целевой трафик из поисковых систем Google и Яндекс на ваш сайт.',
    buttonText: 'что мы делаем',
    buttonLink: '#marketing-stages',
  }
  
  const pageTitle = 'Профессиональная настройка Яндекс Директ и Google Adwords'
  const pageDescription = 'Настраиваем Яндекс.Директ и Google Ads. Проанализируем ваш бизнес, конкурентов и ЦА. Подберем только самые целевые ключи. Охватим всех потенциальных клиентов. Закажите настройку контекстной рекламы в веб-студии Ascent.'

  // const keywords = ''

  return (
    <Layout fixedHeader={true}>
      <Seo title={pageTitle} description={pageDescription} />
      <MarketingMainBanner content={bannerContent} svg={<Illustration />} />
      <MarketingInfoSection />
      <MarketingAdvantages />
      <MarketingPlacements />
      <MarketingAudit />
      <MarketingStages />
      <MarketingPrices />
      <FormSection />
    </Layout>
  )
}

export default MarketingPage