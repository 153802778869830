import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Typography } from '@material-ui/core'

import Icon1 from '../../svg/marketing-page/advantage-icon-1.svg'
import Icon2 from '../../svg/marketing-page/advantage-icon-2.svg'
import Icon3 from '../../svg/marketing-page/advantage-icon-3.svg'
import Icon4 from '../../svg/marketing-page/advantage-icon-4.svg'
import Icon5 from '../../svg/marketing-page/advantage-icon-5.svg'

const useStyles = makeStyles((theme) => ({

  wrapper: {
    padding: '3rem 0',
  },

  advantage: {
    padding: '1rem .5rem',
  },

  advantageIconBox: {
    width: '155px',
    height: '155px',
    margin: '0 auto 1.5rem',
    borderRadius: '50%',
    background: [theme.palette.gradientBlue.main],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  advantageInfoBox: {
    textAlign: 'center',
    fontSize: '1.5rem',
  },

  firstLine: {
    textTransform: 'uppercase',
    fontWeight: 600,
    marginBottom: '.5rem',
  },

  '@media (min-width: 768px)': {
    advantagesBox: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },

    advantage: {
      flexBasis: '50%',
      padding: '1.5rem 2rem',
    },
  },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      padding: '3rem 0 7rem',
    },

    advantage: {
      flexBasis: '33.33%',
    },
  },

}))

const MarketingAdvantages = () => {

  const classes = useStyles()

  const advantagesList = [
    {
      icon: <Icon1 />,
      firstLine: 'Целевая аудитория',
      desc: 'Объявления показываются исключительно заинтересованным в товаре или услуге пользователям',
      key: 'marketing-page-advantage-1',
    },
    {
      icon: <Icon2 />,
      firstLine: 'быстрый результат',
      desc: 'Настройка и запуск контекстной рекламы занимает от 2 дней. Первые заказы появляются сразу после размещения',
      key: 'marketing-page-advantage-2',
    },
    {
      icon: <Icon3 />,
      firstLine: 'Точный таргетинг',
      desc: 'Гибкие возможности настройки времени и географии показов объявлений с точностью до дома где живет или работает пользователь (временной таргетинг и геотаргетинг)',
      key: 'marketing-page-advantage-3',
    },
    {
      icon: <Icon4 />,
      firstLine: 'Большой охват',
      desc: 'Количество обращений к поисковым системам в российском сегменте за месяц составляет 7,5 млрд визитов',
      key: 'marketing-page-advantage-4',
    },
    {
      icon: <Icon5 />,
      firstLine: 'Реклама  временных акций',
      desc: 'Быстрое распространение информации о временных предложениях компании',
      key: 'marketing-page-advantage-5',
    },
  ]

  return (
    <Box component="section" className={classes.wrap}>
      <Box className={`${classes.wrapper} wrapper`} py={3}>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Box className={classes.titleBox} mb={{xs: 5, md: 7}}>
              <Typography variant="h2" className={`${classes.title} title`}>преимущества <span>контекстной рекламы</span></Typography>
            </Box>
            <Box className={classes.advantagesBox}>
              {
                advantagesList.map(item => (
                <Box className={classes.advantage} key={item.key}>
                  <Box className={classes.advantageIconBox}>
                    {item.icon}
                  </Box>
                  <Box className={classes.advantageInfoBox}>
                    <p className={classes.firstLine}>{item.firstLine}</p>
                    <p>{item.desc}</p>
                  </Box>
                </Box>
                ))
              }
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default MarketingAdvantages
