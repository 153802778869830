import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Hidden, Typography } from '@material-ui/core'

import LogoGray from '../../svg/logo-grey.svg'

const useStyles = makeStyles((theme) => ({

  wrapper: {
    position: 'relative',
    padding: '2.3rem 0 1rem',
  },

  content: {
    position: 'relative',
    zIndex: 2,
  },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      padding: '60px 0 100px',
    },

    titleBox: {
      width: '60%',
    },

    subtitle: {

      '&.first': {
        paddingBottom: '1rem',
        marginBottom: '1rem',
        borderBottom: '1px solid',
      }
    },

    content: {
      marginBottom: '5px',
    },

    circle: {
      position: 'absolute',
      borderRadius: '50%',
      background: 'linear-gradient(180deg, #ffffff 0%, #c9c9c9 100%)',
      opacity: .3,
      width: '1107px',
      height: '1107px',
      left: '50vw',
      top: '-629px',
      zIndex: 1,

      '& svg': {
        position: 'absolute',
        bottom: '240px',
        left: '350px',
        width: '400px',
        height: 'auto',
      },
    },
  }
}))

const MarketingInfoSection = () => {

  const classes = useStyles()

  return (
    <Box component="section" className={classes.infoWrap} id="design-info-section">
      <Box className={`${classes.wrapper} wrapper`}>
        <Hidden xsDown>
          <div className={classes.circle}>
            <LogoGray />
          </div>
        </Hidden>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Box className={classes.titleBox} mb={{xs: 5, md: 7}}>
              <Typography variant="h2" className={`${classes.title} title`}>для чего нужна <span>контекстная реклама?</span></Typography>
              <Typography variant="body1" className={`${classes.subtitle} first subtitle`}>Контекстая реклама - это самый быстрый способ для привлечения клиентов на ваш сайт. Ваш сайт будет рекламироваться в поисковой выдаче Яндекс и Google и на их сайтах-партнерах в виде текстовых или графических объявлений. Показы осуществляются только по заранее определенным “ключам”, соответствующих вашему бизнесу, что гарантирует максимально целевой трафик клиентов, заинтересованных в товаре или услуге, на ваш сайт.</Typography>
              <Typography variant="body1" className={`${classes.subtitle} subtitle`}>Первых клиентов вы можете получить уже в день запуска рекламной кампаниии.</Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default MarketingInfoSection
